<template>
  <div class="bookings pl-5">
    <v-row class="w-100">
      <v-col
        v-if="!($vuetify.breakpoint.mobile && paramID)"
        class="bookings-list"
        cols="12"
        :md="!$vuetify.breakpoint.mobile && paramID ? 6 : 12"
      >
        <div class="list-header">
          <div
            class="list-head d-flex justify-space-between align-center px-6 pt-5"
          >
            <span>
              <p class="gray--text mt-1">Adventures Bookings ({{ total }})</p>
            </span>
            <base-input
              :style="$vuetify.breakpoint.mobile ? { width: '100px' } : {}"
              placeholder="Search Bookings"
              @input="searchBooking"
              :rules="[]"
              v-model="searchWord"
            ></base-input>
          </div>
        </div>
        <!-- table -->
        <div v-if="loading">
          <v-data-table
            :headers="headers"
            :items="[{}, {}, {}, {}]"
            class="users-table"
            height="calc(100vh - 60px - 70px)"
            mobile-breakpoint="0"
            v-if="loading"
          >
            <template v-slot:item>
              <tr>
                <td v-for="(item, i) in headers" :key="i">
                  <v-skeleton-loader type="text"></v-skeleton-loader>
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
        <!-- table -->
        <v-data-table
          v-if="!loading"
          :headers="headers"
          :items="bookings"
          item-key="_id"
          :item-class="
            (item) => {
              return paramID == item._id
                ? 'gray lighten-4 cursor-pointer'
                : 'cursor-pointer';
            }
          "
          class="users-table"
          height="calc(100vh - 60px - 70px)"
          :page.sync="page"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :items-per-page.sync="itemsPerPage"
          :footer-props="{ 'items-per-page-options': [5, 15, 30, 50] }"
          :server-items-length="total"
          mobile-breakpoint="0"
          @click:row="viewItem"
          @update:page="fetchList"
          @update:items-per-page="fetchList"
          @update:sort-by="fetchList"
          @update:sort-desc="fetchList"
          fixed-header
        >
          <template v-slot:item.name="{ item }">
            <div class="d-flex align-center">
              <div class="mr-2 mt-2">
                <img
                  v-if="item.userId && item.userId.picture"
                  class="user-avatar"
                  :src="item.userId.picture.url"
                  alt=""
                />
                <img v-else class="user-avatar" :src="avatar" alt="" />
              </div>
              <div>
                <span class="d-block font-13 white-grey--text">{{
                  item.userId?.name
                }}</span>
              </div>
            </div>
          </template>
          <template v-slot:item.experience="{ item }">
            <span
              v-if="item.activityId"
              class="d-block font-13 white-grey--text"
              >{{ item.activityId.title }}</span
            >
          </template>
          <template v-slot:item.status="{ item }">
            <span :class="currentStatusColor(item.bookingStatus)">{{
              bookingStatus(item.bookingStatus)
            }}</span>
          </template>
          <template v-slot:item.amount="{ item }">
            <span class="d-block font-13 white-grey--text"
              >${{ item.amount }}</span
            >
          </template>
          <template v-slot:item.payment="{ item }">
            <span class="d-block font-13 white-grey--text">{{
              getPaymentMethod(item.paymentMethod)
            }}</span>
          </template>
          <template v-slot:item.createdAt="{ item }">
            <span class="d-block font-13 white-grey--text">{{
              $moment(item.createdAt).format("LLLL")
            }}</span>
          </template>
        </v-data-table>
      </v-col>

      <!-- Details -->
      <v-col
        cols="12"
        :class="
          $vuetify.breakpoint.mobile
            ? 'maint-item pa-0 pt-10'
            : 'main-item pa-10 pr-5'
        "
        :md="6"
        v-if="paramID"
        :style="
          $vuetify.breakpoint.mobile ? {} : { borderLeft: '1px solid black' }
        "
      >
        <div
          class="cursor-pointer"
          :style="
            $vuetify.breakpoint.mobile
              ? {
                  position: 'absolute',
                  right: '10px',
                  top: '15px',
                  zIndex: '5'
                }
              : {
                  position: 'absolute',
                  left: 'calc(50% - 24px)'
                }
          "
          @click="collapse"
        >
          <v-icon size="40" color="black">mdi-progress-close</v-icon>
        </div>
        <div class="item-header">
          <v-tabs
            v-model="selectedTab"
            centered
            active-class="secondary--text"
            color="secondary"
            background-color="transparent"
          >
            <v-tab><span class="font-12">Details</span></v-tab>
            <v-tab><span class="font-12">Customer</span></v-tab>
            <v-tab><span class="font-12">Activity</span></v-tab>
          </v-tabs>
        </div>
        <v-tabs-items v-model="selectedTab" class="tabs-items mt-2">
          <v-tab-item>
            <div class="tab-item" v-if="booking">
              <div class="booking-details">
                <v-row class="pt-5 px-5" v-if="booking">
                  <v-col class="py-0 font-500 mb-5" cols="12" md="12">
                    <div style="float: right">
                      <v-btn
                        color="success"
                        :disabled="!booking.bookingCode"
                        @click="acceptReject(true)"
                        >Accept</v-btn
                      >
                      <v-btn
                        :disabled="!booking.bookingCode"
                        @click="acceptReject(false)"
                        class="ml-2"
                        color="error"
                        >Reject</v-btn
                      >
                    </div>
                  </v-col>
                  <v-col class="py-0 font-500" cols="12" md="4">
                    <p>Number of people</p>
                  </v-col>
                  <v-col class="py-0" cols="12" md="8">
                    <p>{{ booking.numberOfPeople }}</p>
                  </v-col>
                  <v-col class="py-0 font-500" cols="12" md="4">
                    <p>Number of pets</p>
                  </v-col>
                  <v-col
                    class="py-0"
                    :class="booking.pets ? 'green--text' : 'red--text'"
                    cols="12"
                    md="8"
                  >
                    <p>{{ booking.pets ? booking.numberOfPets : "No Pets" }}</p>
                  </v-col>
                  <v-col class="py-0 font-500" cols="12" md="4">
                    <p>Children</p>
                  </v-col>
                  <v-col
                    class="py-0"
                    :class="booking.childrens ? 'green--text' : 'red--text'"
                    cols="12"
                    md="8"
                  >
                    <p>{{ booking.childrens ? "Yes" : "No" }}</p>
                  </v-col>
                  <v-col class="py-0 font-500" cols="12" md="4">
                    <p>Booking on</p>
                  </v-col>
                  <v-col class="py-0" cols="12" md="8">
                    <p>{{ $moment(booking.createdAt).format("LL") }}</p>
                  </v-col>
                  <v-col class="py-0 font-500" cols="12" md="4">
                    <p>Start Date</p>
                  </v-col>
                  <v-col class="py-0" cols="12" md="8">
                    <p>{{ $moment(booking.startDate).format("LL") }}</p>
                  </v-col>
                  <v-col class="py-0 font-500" cols="12" md="4">
                    <p>End Date</p>
                  </v-col>
                  <v-col class="py-0" cols="12" md="8">
                    <p>{{ $moment(booking.endDate).format("LL") }}</p>
                  </v-col>
                  <v-col class="py-0 font-500" cols="12" md="4">
                    <p>Status</p>
                  </v-col>
                  <v-col
                    class="py-0"
                    :class="currentStatusColor(booking.bookingStatus)"
                    cols="12"
                    md="8"
                  >
                    <p>{{ bookingStatus(booking.bookingStatus) }}</p>
                  </v-col>
                  <v-col class="py-0 font-500" cols="12" md="4">
                    <p>Paid</p>
                  </v-col>
                  <v-col
                    class="py-0"
                    :class="booking.isPaid ? 'green--text' : 'red--text'"
                    cols="12"
                    md="8"
                  >
                    <p>{{ booking.isPaid ? "Yes" : "No" }}</p>
                  </v-col>
                  <v-col class="py-0 font-500" cols="12" md="4">
                    <p>Total payment</p>
                  </v-col>
                  <v-col class="py-0" cols="12" md="8">
                    <p>${{ booking.amount }}</p>
                  </v-col>
                  <v-col class="py-0 font-500" cols="12" md="4">
                    <p>Commision</p>
                  </v-col>
                  <v-col class="py-0" cols="12" md="8">
                    <p>{{ booking.commision }}%</p>
                  </v-col>
                  <v-col class="py-0 font-500" cols="12" md="4">
                    <p>Extra amount</p>
                  </v-col>
                  <v-col class="py-0" cols="12" md="8">
                    <p>${{ booking.extraAmount }}</p>
                  </v-col>
                  <v-col class="py-0 font-500" cols="12" md="4">
                    <p>Service amount</p>
                  </v-col>
                  <v-col class="py-0" cols="12" md="8">
                    <p>${{ booking.servicesAmount }}</p>
                  </v-col>
                  <v-col class="py-0 font-500" cols="12" md="4">
                    <p>Discount amount</p>
                  </v-col>
                  <v-col class="py-0" cols="12" md="8">
                    <p>${{ booking.discountAmount }}</p>
                  </v-col>
                  <v-col
                    class="pa-0 d-flex"
                    cols="12"
                    md="12"
                    v-for="option in booking.options"
                    :key="option._id"
                  >
                    <v-col class="py-0 font-500" cols="12" md="4">
                      <p>{{ option.description }}</p>
                    </v-col>
                    <v-col class="py-0" cols="12" md="8">
                      <p>${{ option.price }}</p>
                    </v-col>
                  </v-col>
                  <v-col class="py-0 font-500" cols="12" md="4">
                    <p>Online payment fees</p>
                  </v-col>
                  <v-col class="py-0" cols="12" md="8">
                    <p>${{ booking.onlinePaymentFees }}</p>
                  </v-col>
                  <v-col class="py-0 font-500" cols="12" md="4">
                    <p>Coupon code</p>
                  </v-col>
                  <v-col class="py-0" cols="12" md="8">
                    <p>{{ booking.coupon || "-" }}</p>
                  </v-col>
                  <v-col class="py-0 font-500" cols="12" md="4">
                    <p>Payment code</p>
                  </v-col>
                  <v-col class="py-0" cols="12" md="8">
                    <p>{{ booking.transaction || "-" }}</p>
                  </v-col>
                  <v-col class="py-0 font-500" cols="12" md="4">
                    <p>Payment method</p>
                  </v-col>
                  <v-col class="py-0" cols="12" md="8">
                    <p>{{ getPaymentMethod(booking.paymentMethod) }}</p>
                  </v-col>
                  <v-col
                    v-if="booking.bookingCode"
                    class="py-0 font-500"
                    cols="12"
                    md="4"
                  >
                    <p>Booking code</p>
                  </v-col>
                  <v-col
                    v-if="booking.bookingCode"
                    class="py-0"
                    cols="12"
                    md="8"
                  >
                    <p>{{ booking.bookingCode }}</p>
                  </v-col>
                </v-row>
              </div>
            </div>
            <p v-else class="text-center">No Booking Details</p>
          </v-tab-item>
          <v-tab-item>
            <user-details
              v-if="user"
              class="tab-item"
              :item="user"
              :viewOnly="true"
            />
            <p v-else class="text-center">No User Details</p>
          </v-tab-item>
          <v-tab-item>
            <activity-details
              v-if="booking && booking.activityId"
              :districts="districts"
              class="tab-item"
              :item="{ data: booking.activityId }"
              :viewOnly="true"
            />
            <p v-else class="text-center">No Activity Details</p>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
const SortEnum = {
  NAMEASC: 1,
  NAMEDESC: 2,
  EXPERIENCEASC: 3,
  EXPERIENCEDESC: 4,
  STATUSASC: 5,
  STATUSDESC: 6,
  AMOUNTASC: 7,
  AMOUNTDESC: 8,
  PAYMENTASC: 9,
  PAYMENTDESC: 10,
  CREATEDATASC: 11,
  CREATEDATDESC: 12,

  init: function (sortBy, sortDesc) {
    const descSuffix = sortDesc ? "DESC" : "ASC";
    return this[
      `${sortBy
        .replaceAll(" ", "")
        .replaceAll(".", "")
        .replaceAll("_", "")
        .toUpperCase()}${descSuffix}`
    ];
  }
};
import ActivityDetails from "@/components/activity/ItemBody";
import UserDetails from "../../../components/User/UserDetails.vue";
export default {
  components: {
    ActivityDetails,
    UserDetails
  },
  data: () => ({
    avatar: require("@/assets/images/global/avatar.png"),
    booking: undefined,
    paramID: "",
    bookings: [],
    headers: [
      { text: "Name", value: "name", width: "50px" },
      { text: "Experience", value: "experience", width: "100px" },
      { text: "Status", value: "status", width: "100px" },
      { text: "Amount", value: "amount", width: "50px" },
      { text: "Payment", value: "payment", width: "100px" },
      {
        text: "Booked On",
        value: "createdAt",
        width: "150px"
      }
    ],
    source: undefined,
    loading: true,
    sortBy: "createdAt",
    sortDesc: true,
    itemsPerPage: 15,
    searchWord: undefined,
    total: 0,
    page: 1,
    selectedTab: 0,
    activity: undefined,
    districts: [],
    user: undefined
  }),
  watch: {
    "$route.params": {
      async handler() {
        this.paramID = this.$route.params.id;
        await this.fetchItemByID();
      }
    }
  },
  methods: {
    async searchBooking(searchWord) {
      this.bookings = [];
      this.page = 1;
      this.searchWord = searchWord;
      await this.fetchList();
      if (!this.$vuetify.breakpoint.mobile) {
        if (!this.$vuetify.breakpoint.mobile) {
          if (!searchWord || searchWord?.length == 0) {
            this.paramID = this.$route.params.id;
            this.fetchItemByID();
          } else if (this.bookings?.length > 0) {
            this.paramID = this.bookings[0]._id;
            this.fetchItemByID();
          } else {
            this.paramID = undefined;
            this.booking = undefined;
          }
        }
      }
    },
    async viewItem(item) {
      this.$router.push(`/bookings/${item._id}`);
    },
    async fetchList() {
      this.loading = true;
      if (this.source) {
        this.source.cancel();
      }
      this.source = this.$axios.CancelToken.source();
      const currentSource = this.source;
      const search =
        this.searchWord && this.searchWord.length > 0
          ? `&searchWord=${this.searchWord}`
          : "";
      const sort = this.sortBy
        ? `&sort=${SortEnum.init(this.sortBy, this.sortDesc)}`
        : "";
      var pageSize = this.itemsPerPage;
      if (this.itemsPerPage == -1) {
        pageSize = "";
      }
      let { data } = await this.$axios.get(
        `activityInstance/all/new?pageSize=${pageSize}&pageNumber=${this.page}${sort}${search}`
      );
      if (data) {
        this.bookings = data.data;
        this.total = data.count;
      }
      if (currentSource === this.source) {
        this.loading = false;
      }
    },
    bookingStatus(statusId) {
      switch (statusId) {
        case 1:
          return "waiting for payment";
        case 2:
          return "Request is Confirmed";
        case 3:
          return "Booking Confirmed";
        case 4:
          return "Cancelled";
        case 5:
            return "Saved for AI"
        default:
          return "Pending";
      }
    },
    currentStatusColor(status) {
      if (status == 0) return "yellow--text";
      if (status == 1) return "orange--text";
      if (status == 2) return "yellow--text";
      if (status == 3) return "green--text";
      if (status == 4) return "red--text";
    },
    getPaymentMethod(paymentMethodId) {
      if (paymentMethodId === 0) {
        return "Online Payment";
      } else {
        return "Cash United";
      }
    },
    async fetchDistricts() {
      let { data } = await this.$axios.get("/activity/all/selectors");
      if (data) {
        this.districts = data.districts;
      }
    },
    async fetchUser(userID) {
      if (userID) {
        let { data } = await this.$axios.get(`/users/${userID}`);

        if (data) {
          this.user = data.data;
        }
      } else {
        this.user = undefined;
      }
    },
    async fetchItemByID() {
      if (this.paramID) {
        let { data } = await this.$axios.get(
          `/activityInstance/${this.paramID}`
        );

        if (data) {
          this.booking = data.data;
          await this.fetchUser(this.booking.userId?._id);
        }
      }
    },
    async acceptReject(accept) {
      if (this.booking && this.booking.bookingCode) {
        let { data } = await this.$axios.post("booking-request/accept-reject", {
          code: this.booking.bookingCode,
          isAccepted: accept
        });
        if (data) {
          this.$store.dispatch("showSnack", {
            text: `Booking ${accept ? "Accepted" : "Rejected"}`,
            color: "success"
          });
        } else {
          this.$store.dispatch("showSnack", {
            text: "Something went wrong",
            color: "error"
          });
        }
      }
    },
    collapse() {
      this.$router.push("/bookings");
      this.paramID = undefined;
    }
  },
  async created() {
    this.paramID = this.$route.params.id;
    await this.fetchList();
    await this.fetchDistricts();
    await this.fetchItemByID();
  }
};
</script>

<style lang="scss" scoped>
.bookings {
  background: white;
  height: 100vh;
  overflow: hidden;
  .user-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
  }
  .list-header {
    height: 70px;
  }
  .users-table {
    overflow: auto;
  }
  .item-header {
    height: 50px;
    overflow: hidden;
  }
  .tabs-items {
    overflow: hidden;
  }
  .tab-item {
    height: calc(100vh - 100px);
    overflow-y: auto;
    overflow-x: hidden;
  }
  .user-details-details {
    overflow: auto;
    height: calc(100vh - 300px);
    padding-bottom: 20px;
  }
  .booking-details {
    height: calc(100vh - 100px);
    overflow-y: auto;
    overflow-x: hidden;
  }
}
</style>
